const browserLang = require('browser-lang').default
const { navigate, withPrefix } = require('gatsby')

const languages = require('./src/languages')

exports.onClientEntry = () => {
  // Add Intl polyfill support
  if (global.Intl) {
    import('intl')
      .then(() =>
        Promise.all([
          import('intl/locale-data/jsonp/en.js'),
          import('intl/locale-data/jsonp/fr.js'),
        ])
      )
      .catch(error => {
        throw error
      })
  }
}

exports.onPreRouteUpdate = ({ location }) => {
  // Redirect users to their prefered language endpoints
  const isLanguageDefined = languages.locales.includes(
    location.pathname.split('/')[1]
  )

  if (!isLanguageDefined) {
    const browserLocale = browserLang({
      languages: languages.locales,
      fallback: languages.defaultLocale,
    })

    navigate(withPrefix(`/${browserLocale}${location.pathname}`), {
      replace: true,
    })
  }
}

exports.onRouteUpdate = function({ location }) {
  if (process.env.NODE_ENV === 'production' && window && typeof window.fbq === 'function') {
    const isLanguageDefined = languages.locales.includes(
      location.pathname.split('/')[1]
    )

    if (isLanguageDefined) {
      window.fbq('track', 'PageView')
      window.fbq('track', 'ViewContent')
    }
  }
}

exports.onInitialClientRender = () => {
  var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
  (function(){
  var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
  s1.async=true;
  s1.src='https://embed.tawk.to/64bff12fcc26a871b02b2a94/1h66sc8r9';
  s1.charset='UTF-8';
  s1.setAttribute('crossorigin','*');
  s0.parentNode.insertBefore(s1,s0);
  })();
  }
